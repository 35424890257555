import React, { useState } from "react";
import { Grid } from "@mui/material";
import MessageHeading from "./message-heading/MessageHeading";
import MessageDetails from "./message-details/MessageDetails";

const Message = () => {

  //selected client Ids
  const [selectedClientId , setSelectedClientId] = useState<string>('');

  return (
    <>
      <div className="pageTemplate mapDetailsTemplate">
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <MessageHeading selectedClientId={selectedClientId} />
          </Grid>
          <Grid item xs={12}>
            <MessageDetails setSelectedClientId={setSelectedClientId} />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Message;
