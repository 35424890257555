import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { ScheduleService } from "../../../service/schedule.service";
import { setResponseValue } from "../api-response/api-response";
import { IScheduleDatalist, NewDataCatalog } from "../../../type/schedule";
import {
  DefaultParentScheduleSetting,
  DefaultRebookingOptionsResult,
} from "../../../constants/schedule";

const initialState: IScheduleDatalist = {
  clientsDetails: [],
  parentScheduleSetting: DefaultParentScheduleSetting,
  Rebookings: DefaultRebookingOptionsResult,
  ClientReasonCatalog: [],
  BillableCatalog: [],
  NonBillableCatalog: [],
};

const scheduleSlice = createSlice({
  name: "scheduleSlice",
  initialState: initialState,
  reducers: {
    setClientCancelReason: (state, action) => {
      let cancelReason = action.payload.filter(
        (item: NewDataCatalog) => item.catalogName === "Client Cancel Reason"
      );
      return {
        ...state,
        ClientReasonCatalog: cancelReason,
      };
    },
    setBillableCatalog: (state, action) => {
      let Billable = action.payload.filter(
        (item: NewDataCatalog) => item.catalogName === "Billable Activity"
      );
      return {
        ...state,
        BillableCatalog: Billable,
      };
    },
    setNonBillableCatalog: (state, action) => {
      let NonBillable = action.payload.filter(
        (item: NewDataCatalog) => item.catalogName === "Non Billable Activity"
      );
      return {
        ...state,
        NonBillableCatalog: NonBillable,
      };
    },
    clearRebookings: (state, action) => {
      return {
        ...state,
        Rebookings: DefaultRebookingOptionsResult,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(GetChildSchedules.fulfilled, (state, action) => {
      return {
        ...state,
        clientsDetails: action.payload,
      };
    });
    builder.addCase(getScheduleSettings.fulfilled, (state, action) => {
      return {
        ...state,
        parentScheduleSetting: action.payload,
      };
    });
    builder.addCase(getDataCatalog.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        return {
          ...state,
          NewDataCatalog: [...action.payload],
        };
      }
    });
    builder.addCase(GetRebookingProviders.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        return {
          ...state,
          Rebookings: action.payload,
        };
      }
    });
  },
});

export const GetChildSchedules = createAsyncThunk(
  "parent/childSchedules",
  async ({ childIds }: { childIds: string[] }, { getState, dispatch }) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { data } = await ScheduleService.getChildSchedules(childIds);
      return data;
    } catch (e: any) {
      console.log(e);
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const getScheduleSettings = createAsyncThunk(
  "/parent/scheduleSettings",
  async (_, { dispatch }) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { data } = await ScheduleService.getScheduleSettings();
      if (data) {
        return data;
      }
    } catch (e: any) {
      console.log(e);
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const getDataCatalog = createAsyncThunk(
  "/getCatalog",
  async (_, { dispatch }) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { data } = await ScheduleService.getDataCatalog();
      if (data) {
        dispatch(setClientCancelReason(data));
        dispatch(setBillableCatalog(data));
        dispatch(setNonBillableCatalog(data));
      }
      let cancelReason = data.filter(
        (item: any) => item.catalogName === "Client Cancel Reason"
      );
      return cancelReason;
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const GetRebookingProviders = createAsyncThunk(
  "parent/rebooking",
  async (
    {
      bookingId,
      cancelRequestBy,
    }: {
      bookingId: string;
      cancelRequestBy: string;
    },
    { getState, dispatch }
  ) => {
    const payloadData = {
      bookingId: bookingId,
      cancelRequestBy: cancelRequestBy,
    };
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { data } = await ScheduleService.getRebookingProviders(payloadData);
      return data;
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({ name: "message", value: e?.response?.data?.message })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);
export const {
  setClientCancelReason,
  clearRebookings,
  setBillableCatalog,
  setNonBillableCatalog,
} = scheduleSlice.actions;
export default scheduleSlice;
