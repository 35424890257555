import {AvailabilityDetail} from "../type/provider"
import { formattedDate } from "../constants/comman";

export const availabilityOverlap = (d1: any, data: AvailabilityDetail[]) => {
    let overlap: any[] = [];

    data?.forEach((a) => {
      if (
        new Date(d1) <= new Date(formattedDate(a.endDate)) &&
        new Date(d1) >= new Date(formattedDate(a.beginDate))
      ) {
        overlap = [...a.availabilitySelectedDays];
      }
    });

    return overlap;
};