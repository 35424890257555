import React from "react";
import {
  alpha,
  Box,
  Button,
  Grid,
  InputBase,
  styled,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import Icons from "../../assets/icons";
import SearchIcon from "@mui/icons-material/Search";
import { ReactComponent as Help } from "../../assets/icons/Help.svg";
import { ReactComponent as Notification } from "../../assets/icons/notification.svg";
import { ReactComponent as Calendar } from "../../assets/icons/Calendar.svg";
import { ReactComponent as Message } from "../../assets/icons/message.svg";
import { ReactComponent as Trash } from "../../assets/icons/trash.svg";
import profile from "../../assets/images/profile.png";
import "./messageCenter.scss";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
    height: "50px",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    width: "100%",
    height: "50px",
    borderRadius: 0,
  },
}));

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Center:React.FC = () => {

  //#region variable region

  //store value 
  const [value, setValue] = React.useState(0);

  //#endregion
  
  //#region method region

  // handle change state
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  //#endregion


  return (
    <Box className="massageTemplate">
      <Box className="sidebar">
        <Box className="sidebar__head">
          <Typography className="sidebar__head-title">
            Message Center
          </Typography>
          <Typography className="sidebar__head-subText">
            <Help /> Help and Feedback
          </Typography>
          <Button className="button">Send a Message</Button>
        </Box>
        <Box className="sidebar__tab">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              label={
                <>
                  <Typography>
                    <Notification />
                    Notifications
                  </Typography>
                  <span>5</span>
                </>
              }
              {...a11yProps(0)}
            />
            <Tab
              label={
                <>
                  <Typography>
                    <Calendar />
                    Visit Updates
                  </Typography>
                </>
              }
              {...a11yProps(1)}
            />
            <Tab
              label={
                <>
                  <Typography>
                    <Message />
                    Provider Message
                  </Typography>
                </>
              }
              {...a11yProps(2)}
            />
            <Tab
              label={
                <>
                  <Typography>
                    <Trash />
                    Trash
                  </Typography>
                </>
              }
              {...a11yProps(3)}
            />
          </Tabs>
        </Box>
      </Box>
      <Box className="massageTemplate__page">
        <TabPanel value={value} index={0}>
          <Box className="notificationsPage">
            <Box className="notificationsPage__head">
              <Box className="notificationsPage__heading">
                <Typography className="notificationsPage__title">
                  Notifications
                </Typography>
                <Typography className="notificationsPage__subText">
                  Showing 10 of 10
                </Typography>
              </Box>
              <Search className="search-block">
                <SearchIconWrapper className="search-block__icon">
                  <SearchIcon color="primary" />
                </SearchIconWrapper>
                <StyledInputBase
                  style={{ color: "black" }}
                  placeholder="Search Notifications"
                />
              </Search>
            </Box>
            <div className="notificationBlock">
              {Array(10)
                .fill(1)
                .map(() => {
                  return (
                    <div className="notificationCard">
                      <div className="notificationCard__img">
                        <img
                          src={profile}
                          alt="profile"
                          className="img-fluid"
                        />
                      </div>
                      <div className="notificationCard__info">
                        <div className="notificationCard__head">
                          <div className="notificationCard__heading">
                            <Typography className="notificationCard__title">
                              Provider Message
                            </Typography>
                            <Typography className="notificationCard__subTitle">
                              Intake progress
                            </Typography>
                          </div>
                          <div className="notificationCard__date">
                            12/12/2022
                          </div>
                        </div>
                        <p className="notificationCard__text">
                          Stay up to date on your child’s Intake status with New
                          Client paperwork, insurance authorization, Lorem ipsum
                          dolor sit amet, consectetur uis...
                        </p>
                      </div>
                    </div>
                  );
                })}
              
            </div>
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
          Visit Updates
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Grid container className="homepage_layout">
            <Grid item className="announcement">
              <Typography>Welcome, Monica!</Typography>
              <Grid container>
                {Array(3)
                  .fill(1)
                  .map(() => {
                    return (
                      <Grid lg={3} sm={6} item className="announcement_box">
                        <img src={Icons.SpeakerIcon} />
                        <div className="box_text">
                          <span>Stay up to date with the intake process</span>
                          <p>
                            No more waiting for updates regarding Intake
                            paperwork, insurance authorization, scheduling and
                            more. See More
                          </p>
                        </div>
                      </Grid>
                    );
                  })}
              </Grid>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={3}>
          Trash
        </TabPanel>
      </Box>
    </Box>
  );
};

export default Center;
