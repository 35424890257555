import React, { useEffect, useState } from "react";
import { Box, Button, Grid } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useUser } from "@descope/react-sdk";

import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { getChildList } from "../../../redux/features/client-main/client-list-slice";

import { IChildList } from "../../../type/child-info";

import ChildList from "../childlist/ChildList";
import AllMessages from "../all-messages/AllMessages";

import "./message-details.scss";

interface IMessageDetails {
  setSelectedClientId: React.Dispatch<React.SetStateAction<string>>;
}

const MessageDetails: React.FC<IMessageDetails> = ({ setSelectedClientId }) => {
  //#region variable region

  //dispatch
  const dispatch = useAppDispatch();

  // get user data
  const { user } = useUser();

  //fetch all messages
  const { openedMessage } = useAppSelector((state) => state.messagesSlice);

  //handles Mobile view Responsiveness
  const [isMobileView, setIsMobileView] = useState<boolean>(false);

  //store Childs Lists
  const ChildDataLists = useAppSelector((state) => state.clientSlice.ChildList);

  //store selected tabs
  const [selectedChild, setSelectedChild] = useState<IChildList>();

  //#endregion

  //#region useEffect region

  useEffect(() => {
    if (user?.email) {
      dispatch(getChildList({ email: user?.email! }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  //#endregion

  return (
    <>
      <Box sx={{ flexGrow: 1 }} className="mapWrapper">
        <Grid container spacing={4} columns={16}>
          {((selectedChild === undefined && isMobileView) || !isMobileView) && (
            <ChildList
              setSelectedClientId={setSelectedClientId}
              setSelectedChild={setSelectedChild}
              selectedChild={selectedChild}
              ChildDataLists={ChildDataLists}
              isMobileView={isMobileView}
              setIsMobileView={setIsMobileView}
            />
          )}

          {((selectedChild !== undefined && isMobileView) || !isMobileView) && (
            <>
              {isMobileView && !openedMessage && (
                <div style={{ paddingLeft: "15px", paddingTop: "15px" }}>
                  <Button
                    onClick={() => setSelectedChild(undefined)}
                    className="backBtn"
                    startIcon={<KeyboardBackspaceIcon />}
                  >
                    Back
                  </Button>
                </div>
              )}
              <AllMessages
                selectedChild={selectedChild}
                isMobileView={isMobileView}
                setIsMobileView={setIsMobileView}
              />
            </>
          )}
        </Grid>
      </Box>
    </>
  );
};

export default MessageDetails;
