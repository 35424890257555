import axios from "../axios/axios";

//for fetching schedule list
export const getChildSchedules = async (childIds: string[]) => {
  try {
    // console.log("childIds:", childIds);
    const { data } = await axios.patch(`/parent/getChildSchedules`, {
      childIds,
    });
    return data;
  } catch (error: any) {
    throw error?.response?.data;
  }
};

//for settings from admin app
const getScheduleSettings = async () => {
  try {
    const { data } = await axios.get(`/parentSetting`);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

// export const editService = async (bookingData: any, fromPlanning: boolean) => {
//   try {
//     const { data } = await axios.put<any>(
//       `/parent/rebooking/${bookingData.serviceId}`,
//       {
//         bookedClientId: bookingData.bookedClientId,
//         bookedProviderId: bookingData.bookedProviderId,

//         updateOption: bookingData.updateOption,
//         specificDate: bookingData.specificDate,
//         fromTime: bookingData.fromTime,
//         toTime: bookingData.toTime,

//         serviceType: bookingData.serviceType,
//         serviceLocation: bookingData.serviceLocation,
//         description: bookingData.description,

//         category: bookingData.category,

//         fromPlanning: fromPlanning,
//       }
//     );
//     return { data };
//   } catch (e: any) {
//     throw e?.response?.data;
//   }
// };

//used for create bookings
export const bookService = async (bookingData: any, fromPlanning: boolean) => {
  try {
    const { data } = await axios.post<any>(`/client/book-service`, {
      ...bookingData,
      fromPlanning,
    });
    return { data };
  } catch (e: any) {
    throw e?.response?.data;
  }
};

//cancel bookings
export const cancelService = async (
  bookingData: any,
  fromPlanning: boolean
) => {
  try {
    const { data } = await axios.put<any>(
      `/parent/cancleBooking/${bookingData.serviceId}`,
      {
        cancelOption: bookingData.cancelOption,
        specificDate: bookingData.specificDate,
        bookedClientId: bookingData.bookedClientId,
        bookedProviderId: bookingData.bookedProviderId,
        whoCancelled: bookingData.whoCancelled,
        cancellationReason: bookingData.cancellationReason,
        isDelete: bookingData.isDelete,
        fromPlanning: fromPlanning,
      }
    );
    return { data };
  } catch (e: any) {
    throw e?.response?.data;
  }
};

export const getDataCatalog = async () => {
  try {
    const { data } = await axios.get(`/datacatalog`);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

// export const getRebooking = async (bookingId: any) => {
//   try {
//     const { data } = await axios.get<any>(
//       `/parent/rebooking/${bookingId}`
//     );
//     return data;
//   } catch (e: any) {
//     throw e?.response?.data;
//   }
// };

//used to fetch provider available for bookings
export const getRebookingProviders = async (payloadData: {
  bookingId: string;
  cancelRequestBy: string;
}) => {
  try {
    const { data } = await axios.post<any>("/parent/rebooking", payloadData);
    return data;
  } catch (error: any) {
    throw error;
  }
};

export const ScheduleService = {
  getChildSchedules,
  getScheduleSettings,
  // editService,
  cancelService,
  getDataCatalog,
  getRebookingProviders,
  bookService,
};
