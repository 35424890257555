import React from "react";
import ReactHtmlParser from "html-react-parser";
import { AllUserMessage } from "../../../type/message";
import { isHtml, htmlToPlainText } from "../../../constants/message";

interface IMessageformatter {
  message: AllUserMessage;
}

const MessageFormatter: React.FC<IMessageformatter> = ({ message }) => {
  const htmlString: string = `${message.message}`;

  if (isHtml(htmlString)) {
    //show first line of htmlString
    let firstLine = htmlString.split("\n")[0];
    if (firstLine?.length > 0) {
      firstLine = firstLine.slice(0, 120) + "...";
      firstLine = htmlToPlainText(firstLine);
    }
    // show next line as "See More Details...";
    return (
      <div className="">
        <span
          style={{ cursor: "pointer" }}
        >
          {firstLine}
        </span>
        <p></p>
        <span style={{ color: "#096dd9", cursor: "pointer" }}>
          {"See More Details..."}
        </span>
      </div>
    );
  } else {
    return <div>{ReactHtmlParser(htmlString)}</div>;
  }
  
};

export default MessageFormatter;
