import React, { useEffect } from "react";
import { useAppDispatch } from "../../redux/store";
import { patchIntakeformstatusById } from "../../redux/features/client-main/client-list-slice";
import { useNavigate } from "react-router-dom";
const Redirector = () => {
  //#region variable region

  // routing object
  const navigate = useNavigate();

  //dispatch
  const dispatch = useAppDispatch();

  //#endregion

  //#region useEffect region

  //fetch and redirect
  useEffect(() => {
    let ID = localStorage.getItem("Id");
    if (ID !== null) {
      dispatch(patchIntakeformstatusById({ formId: ID }));
      localStorage.removeItem("Id");
      navigate("/");
    }
  }, []);

  //#endregion

  return <></>;
};

export default Redirector;
