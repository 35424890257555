import React, { useEffect, useState } from "react";
import { RootState, useAppDispatch } from "../../../redux/store";
import { useSelector } from "react-redux";
import { AllUserMessage, UserMessages } from "../../../type/message";
import {
  GetParentMessages,
  PatchParentMessages,
  openMessage,
} from "../../../redux/features/messages/message-slice";
import { IChildList } from "../../../type/child-info";
import { Box, Grid, IconButton } from "@mui/material";
import { dateToFromNowDaily } from "../../../helper/reverselist";
import MessageFormatter from "./MessageFormatter";
import { Download } from "@mui/icons-material";

import ClientImg from "../../../assets/images/Image-svg/client-large.svg";
import ProviderImg from "../../../assets/images/Image-svg/provider-large-vertical.svg";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import ImageIcon from "@mui/icons-material/Image";
import { useTranslation } from "react-i18next";
import { getChildList } from "../../../redux/features/client-main/client-list-slice";
import InternalMessages from "../message-subcomments/InternalMessages";
import { allUserMessage } from "../../../constants/message";
import { useUser } from "@descope/react-sdk";

interface IAllMessages {
  selectedChild: IChildList | undefined;
  isMobileView: boolean;
  setIsMobileView: React.Dispatch<React.SetStateAction<boolean>>;
}

const AllMessages: React.FC<IAllMessages> = ({
  selectedChild,
  isMobileView,
  setIsMobileView,
}) => {
  //#region variable region
  //dispatch
  const dispatch = useAppDispatch();

  //translation
  const { t } = useTranslation();

  // get user data
  const { user } = useUser();

  //handle internal messages
  const [internalMessageOpen, setInternalMessageOpen] =
    useState<boolean>(false);

  //fetch all messages
  const { AllMessages, openedMessage } = useSelector<RootState, UserMessages>(
    (state) => state.messagesSlice
  );

  //#endregion

  //#region method region
  //used to select message
  const handleClick = (item: AllUserMessage) => {
    // setInternalMessageOpen(true);
    dispatch(
      openMessage({ ...item, seenUserId: [...item.seenUserId, user.userId] })
    );
    let activeMessageIds: string[] = [];
    if (AllMessages.length > 0) {
      AllMessages.forEach((item) => {
        if (!item.seenUserId.includes(user?.userId)) {
          activeMessageIds.push(item?.id);
        }
        return activeMessageIds;
      });
    }
    try {
      if (activeMessageIds.length > 0) {
        dispatch(PatchParentMessages({ id: activeMessageIds }));
      }
    } catch (error) {
    } finally {
      //to get the new unread message count
      if(selectedChild !== undefined)
      dispatch(GetParentMessages({ id: selectedChild?.id }));
      dispatch(getChildList({ email: user?.email! }));
    }
  };

  //#endregion

  //#region useEffect region
  //fetch getParent all Messages
  useEffect(() => {
    if(selectedChild !== undefined)
    dispatch(GetParentMessages({ id: selectedChild?.id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AllMessages.length, selectedChild?.id]);

  if (openedMessage) {
    return <InternalMessages />;
  }

  //#endregion
  return (
    <>
      <Grid item xs={16} md={12}>
        {AllMessages.length > 0 ? (
          AllMessages.map((item: AllUserMessage) => {
            return (
              <Grid
                className={
                  item.seenUserId.includes(user?.userId)
                    ? "messageInternal-block "
                    : "messageInternal-block active"
                }
                item
                xs={16}
                key={item.id}
                onClick={() => handleClick(item)}
              >
                <Box className="compose-block">
                  <span className="commentListItem__user-img">
                    <span
                      className={
                        !item.seenUserId.includes(user?.userId) ? "active" : ""
                      }
                    ></span>
                    <img
                      src={
                        item.user.profile.url ||
                        (item.user.userType === "Client"
                          ? ClientImg
                          : ProviderImg)
                      }
                      className="userSmallCircle"
                      alt=""
                    />
                  </span>
                  <div className="commentListItem__user-info">
                    <div className="compose-user-heading">
                      <h5 className="compose-user-name">{item?.user?.name}</h5>
                      <h6 className="compose-user-time">
                        <span>{dateToFromNowDaily(item.createdOn)}</span>
                      </h6>
                    </div>
                  </div>
                </Box>
                <Box>
                  <div className="messageTicket-number">
                    <strong>Subject: {item.subject}</strong>
                  </div>
                </Box>
                <Box>
                  <div className="message-listingBlock">
                    <MessageFormatter message={item} />
                  </div>
                </Box>
                <Box>
                  <div
                    style={{
                      textDecoration: "none",
                      display: "flex",
                      gap: "10px",
                    }}
                  >
                    {item?.files.map((file, index) => (
                      <span key={index}>
                        <a
                          rel="noreferrer"
                          target="_blank"
                          href={`${file.url}`}
                          style={{
                            textDecoration: "none",
                            color: "black",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {["JPG", "JPEG", "PNG"].includes(
                            file?.url?.split(".").pop()?.toUpperCase() || ""
                          ) ? (
                            <ImageIcon style={{ color: "#096dd9" }} />
                          ) : (
                            <InsertDriveFileIcon style={{ color: "#096dd9" }} />
                          )}
                          <span
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              maxWidth: "150px",
                            }}
                            title={file.fileName}
                          >
                            {file.fileName}
                          </span>
                          <IconButton style={{ background: "transparent" }}>
                            <Download />
                          </IconButton>
                        </a>
                      </span>
                    ))}
                  </div>
                </Box>
              </Grid>
            );
          })
        ) : (
          <>{t("main.message.no_messages_found")}</>
        )}
      </Grid>
      {/* )} */}
    </>
  );
};

export default AllMessages;
