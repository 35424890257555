import React from "react";
import SurveyComponent from "./SurveyComponent";
import { useLocation, useNavigate } from "react-router-dom";

interface IAssessmentState {
  childId: string;
  FormNo: number;
}

const AssessmentMain = () => {
  //#region Variable region

  // used for navigation
  const navigate = useNavigate();

  //allow to get current location
  const location = useLocation();

  //#endregion`
  return <SurveyComponent count={location.state?.FormNo} />;
};

export default AssessmentMain;
