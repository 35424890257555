import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { useSession, useUser, useDescope } from "@descope/react-sdk";

import {
  Box,
  AppBar,
  Toolbar,
  IconButton,
  alpha,
  InputBase,
  styled,
  Grid,
  Tooltip,
  Avatar,
  Typography,
  useTheme,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
} from "@mui/material";
import { Logout, Menu, Close, Search as SearchIcon } from "@mui/icons-material";

import { useTranslation } from "react-i18next";

import HeaderTab from "../header-tab/HeaderTab";

import { useAppSelector, useAppDispatch } from "../../redux/store";
import {
  setCurrentTenant,
  resetCurrentTenant,
} from "../../redux/features/current-tenant/current-tenant-slice";
import { getOrgInfo } from "../../redux/features/organization/organization-slice";

import { UserTenant } from "../../type/descope";
import { HeadersTabs, Tabs } from "../../constants/tabs";

import ABAEngineLogo from "../../assets/images/logos/abaengine.png";
import English from "../../assets/images/Images-png/united-kingdom.png";
import Spanish from "../../assets/images/Images-png/spain.png";
import Chinese from "../../assets/images/Images-png/china.png";
import "./header.scss";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    width: "100%",
  },
}));

const locales: { title: string; flag: string; value: string }[] = [
  {
    value: "en",
    title: "English",
    flag: English,
  },
  {
    value: "es",
    title: "Spanish",
    flag: Spanish,
  },
  {
    value: "zh-CN",
    title: "Chinese",
    flag: Chinese,
  },
];

const Header: React.FC = () => {
  const { isAuthenticated } = useSession();
  const { logout } = useDescope();
  const { user } = useUser();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const orgLogo = useAppSelector(
    (state) => state.organizationSlice.orgData.orgLogo.url
  );

  const [openSearch, setOpenSearch] = useState(false);
  const [selectedTab, setSelectedTab] = useState(
    localStorage.getItem("selectedTab") || "Home"
  );
  const [openMenu, setOpenMenu] = useState(false);
  const [selectedLang, setSelectedLang] = useState(
    i18n.language === "en-US" ? "en" : i18n.language
  );

  const handleDrawerOpen = () => {
    setOpenMenu(!openMenu);
  };

  const handleSearch = () => {
    setOpenSearch(!openSearch);
  };

  const handleUserLogout = useCallback(() => {
    dispatch(resetCurrentTenant(""));
    logout();
  }, [logout, dispatch]);

  useEffect(() => {
    setSelectedTab("Home");
    dispatch(getOrgInfo());

    if (user) {
      const parentTenants = user.userTenants?.filter((tenant: UserTenant) =>
        tenant.roleNames?.includes("Parent")
      );

      if (parentTenants) {
        const tenantId = parentTenants[0].tenantId;
        dispatch(setCurrentTenant(tenantId));
      }
    }
  }, [user, dispatch]);

  return (
    <Grid container>
      <AppBar
        position="static"
        style={{
          backgroundColor: "white",
          boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Toolbar
          className={openMenu ? "mainHeader openMobileMenu" : "mainHeader"}
          style={{
            color: "black",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box display="flex">
            <IconButton
              className="mainHeader__toggle"
              size="large"
              edge="start"
              sx={{ mr: 2 }}
              style={{ background: "transform", color: "#000" }}
              onClick={handleDrawerOpen}
            >
              <Menu />
            </IconButton>
            <img
              alt="logo"
              src={orgLogo || ABAEngineLogo}
              className="mainHeader__logo"
            />
            <FormControl sx={{ width: "140px" }} size="small">
              <InputLabel id="language">Language</InputLabel>
              <Select
                labelId="language"
                id="demo-simple-select"
                value={selectedLang}
                label="Language"
                onChange={(e) => {
                  setSelectedLang(e.target.value);
                  i18n.changeLanguage(e.target.value);
                }}
              >
                {locales.map((item, i) => (
                  <MenuItem
                    value={item.value}
                    key={i}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    {item.title}
                    <img
                      src={item.flag}
                      alt=""
                      height="30px"
                      width="30px"
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box display="flex" alignItems="center">
            <Box className="mobileSearch" onClick={handleSearch}>
              <SearchIcon color="primary" />
            </Box>
            <Search
              className={
                openSearch ? "search-block active-search" : "search-block"
              }
            >
              <SearchIconWrapper className="search-block__icon">
                <SearchIcon color="primary" />
              </SearchIconWrapper>
              <StyledInputBase
                style={{ color: "black" }}
                placeholder={t("main.search")}
              />
            </Search>
            <Box className="overlay" onClick={handleDrawerOpen} />
            <Box
              display="flex"
              className={
                openMenu
                  ? "mainHeader__menu desk-menu open"
                  : "mainHeader__menu desk-menu"
              }
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                className="mobile-controls"
              >
                <div style={{ display: "flex" }}>
                  <IconButton
                    className="mainHeader__toggle"
                    size="large"
                    edge="start"
                    sx={{ mr: 2 }}
                    style={{
                      background: "transform",
                      color: "#000",
                      marginLeft: "26px",
                      height: "50px",
                    }}
                    onClick={handleDrawerOpen}
                  >
                    <Menu />
                  </IconButton>
                  <img
                    alt="Loading"
                    src={orgLogo || ABAEngineLogo}
                    height="50px"
                    width="auto"
                  />
                </div>

                <Close
                  color="primary"
                  sx={{ marginRight: "10px", cursor: "pointer" }}
                  onClick={handleDrawerOpen}
                />
              </Box>
              <HeaderTab
                onTabClick={(tab: Tabs) => {
                  handleDrawerOpen();
                  setSelectedTab(tab.tabName);
                  localStorage.setItem("selectedTab", tab.tabName);
                  navigate(`/${tab.tabName}`);
                }}
                selectedTab={selectedTab}
                tabs={HeadersTabs}
              />
            </Box>
            {isAuthenticated && (
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Box display="flex" alignItems="center">
                  <Box sx={{ flexGrow: 0 }} className="userBox">
                    <Tooltip
                      title="Open Profile settings"
                      className="userBox__image"
                    >
                      <Avatar alt="userProfile" src={user?.picture || ""} />
                    </Tooltip>
                    <Typography className="userBox__name" component="span">
                      {user?.name}
                    </Typography>
                  </Box>
                  <IconButton
                    className="logout-icon"
                    onClick={handleUserLogout}
                  >
                    <Logout />
                  </IconButton>
                </Box>
              </Box>
            )}
          </Box>
        </Toolbar>
      </AppBar>
    </Grid>
  );
};

export default Header;
