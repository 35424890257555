import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setResponseValue } from "../api-response/api-response";
import { MessagesSlice } from "../../../service/message-service";
import { TranslationData, UserMessages } from "../../../type/message";
import { AllMessagesInfo } from "../../../constants/message";
import TranslationService from "../../../service/translation.service";

const initialState: UserMessages = {
  ...AllMessagesInfo,
};

const parentmessageSlice = createSlice({
  name: "messages-Slice",
  initialState: initialState,
  reducers: {
    openMessage: (state, { payload }) => {
      if (payload !== undefined) {
        return {
          ...state,
          openedMessage: payload,
        };
      }
    },

    setTranslation: (state, { payload }) => ({
      ...state,
      TranslatedMessages: payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(GetParentMessages.fulfilled, (state, action) => {
      if (action.payload) {
        return {
          ...state,
          AllMessages: [...action.payload],
        };
      }
    });
    builder.addCase(getTranslationMessageId.fulfilled, (state, action) => {
      // debugger;
      if (!action.payload) return state;
      const translatedMessage = action.payload.data as TranslationData;
      const { openedMessage } = state;
      if (action.payload.language !== translatedMessage.toLanguage)
        return { ...state, translatedMessage: openedMessage };
      if (!openedMessage) return state;
      return {
        ...state,
        TranslatedMessages: {
          ...openedMessage,
          message: translatedMessage.message,
          subject: translatedMessage.subject,
          comments: openedMessage.comments.map((comment, i) => ({
            ...comment,
            comment: translatedMessage.comment[i] || comment.comment,
          })),
        },
      };
    });
    builder.addCase(PostComments.fulfilled, (state, { payload }) => {
      if (payload) {
        return {
          ...state,
          openedMessage: payload,
        };
      }
    });
  },
});

//fetches the messages
export const GetParentMessages = createAsyncThunk(
  `/parentMessage`,
  async ({ id }: { id: string }, { getState, dispatch }) => {
    try {
      // dispatch(setResponseValue({ name: "pending", value: true }));
      const data = await MessagesSlice.getParentMessage(id);

      return data.data;
    } catch (e: any) {
      //do something if needed
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

//availability
export const PatchParentMessages = createAsyncThunk(
  `/patchparentMessage`,
  async ({ id }: { id: string[] }, { getState, dispatch }) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const data = await MessagesSlice.patchComments(id);

      // return data;
    } catch (e: any) {
      //do something if needed
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

//post create Commets
export const PostComments = createAsyncThunk(
  `/message/postcomments`,
  async (
    {
      messageId,
      entityId,
      comment,
      entityType,
      files,
    }: {
      messageId: string;
      entityId: string;
      comment: string;
      entityType: string;
      files?: any;
    },
    { getState, dispatch }
  ) => {
    // dispatch(setResponseValue({ name: "pending", value: true }));
    try {
      const { data, status, message } = await MessagesSlice.postComments(
        messageId,
        entityId,
        comment,
        entityType,
        files
      );
      if (status) {
        dispatch(GetParentMessages({ id: entityId }));
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));

        return data;
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({ name: "message", value: e?.response?.data?.message })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

//post create message
export const PostCreateMessage = createAsyncThunk(
  `/message/post`,
  async (
    {
      entityId,
      subject,
      message,
      whoCanView,
      type,
      from,
      entityType,
      files,
    }: {
      entityId: string;
      subject: string;
      message: string;
      whoCanView: string[];
      type: string;
      from: string;
      entityType: string;
      files?: any; // Marking 'files' as optional
    },
    { getState, dispatch }
  ) => {
    const payloadData = {
      subject: subject,
      message: message,
      whoCanView: whoCanView,
      type: type,
      from: from,
      entityType: entityType,
    };

    dispatch(setResponseValue({ name: "pending", value: true }));
    try {
      const data = await MessagesSlice.postCreateMessage(
        payloadData,
        entityId,
        files
      );
      if (data.status) {
        dispatch(GetParentMessages({ id: entityId }));
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: data.message }));

        return data;
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({ name: "message", value: e?.response?.data?.message })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

//post create message
export const getTranslationMessageId = createAsyncThunk(
  `/message/getTranslationMessageId`,
  async (
    { language, messageId }: { messageId: string; language: string },
    { dispatch }
  ) => {
    // dispatch(setResponseValue({ name: "pending", value: true }));
    try {
      const { data, status, message } = await TranslationService.getTranslation(
        "Message",  //source type
        messageId, 
        language
      );

      if (status) {
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
        return { data: data.translationData, language };
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({ name: "message", value: e?.response?.data?.message })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const { setTranslation, openMessage } = parentmessageSlice.actions;

export default parentmessageSlice;
