import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../redux/store";
import { IChildList } from "../../type/child-info";
import DocumentSubPage from "./documents";

const Documents: React.FC = () => {
  //#region variable region

  //store Childs Lists
  const ChildDataLists = useAppSelector((state) => state.clientSlice.ChildList);

  //Store selected Child
  const [selectedChild, setSelectedChild] = useState<IChildList>(
    ChildDataLists[0]
  );

  //#endregion

  //#region method region

  //handle tab data
  const handleTabData = (ID: string) => {
    let data = ChildDataLists.filter((item: IChildList) => {
      return item.id === ID;
    });

    setSelectedChild(data[0]);
  };

  //#endregion

  //#region useEffect region

  //re-fetch selected child data
  useEffect(() => {
    if (selectedChild === undefined && ChildDataLists)
      setSelectedChild(ChildDataLists[0]);
  }, [ChildDataLists, selectedChild]);

  //#endregion

  return (
    <Box className="pageTemplate">
      <ul className="innerTabs">
        {ChildDataLists.length > 1
          ? ChildDataLists.map((item: IChildList, index: number) => {
              return (
                <li className="innerTabs__item" key={index}>
                  <button
                    style={{ display: "flex" }}
                    onClick={() => handleTabData(item.id)}
                    className={item.id === selectedChild?.id ? "active" : ""}
                  >
                    {`${item.childFirstName}${" "}${item.childLastName}`}
                  </button>
                </li>
              );
            })
          : ""}
      </ul>

      <DocumentSubPage ChildData={selectedChild} />
    </Box>
  );
};

export default Documents;
