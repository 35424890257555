import { Box } from "@mui/material";
import React, { useEffect } from "react";
import {
  GetChildSchedules,
  getScheduleSettings,
} from "../../redux/features/schedules/schedule-slice";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState, useAppSelector } from "../../redux/store";
import { useSelector } from "react-redux";
import ScheduleCalendar from "./ScheduleCalendar";
import { IClientDetails } from "../../type/client";
import { ParentScheduleSetting } from "../../type/schedule";
import { useUser } from "@descope/react-sdk";
import { getChildList } from "../../redux/features/client-main/client-list-slice";

const ScheduleNew = () => {

  const { user } = useUser();

  //used to dispatch
  const dispatch = useDispatch<AppDispatch>();

  //store Child List
  const childDetailList = useSelector<RootState, IClientDetails[]>(
    (state) => state.scheduleSlice.clientsDetails
  );

  //store Childs Lists
  const childList = useAppSelector((state) => state.clientSlice.ChildList);


  const childIds = childList?.map((item) => item.id);

  //stores schedule setting data
  const scheduleSetting = useSelector<RootState, ParentScheduleSetting>(
    (state) => state.scheduleSlice.parentScheduleSetting
  );
  
  useEffect(() => {
    if (user?.email) {
      dispatch(getChildList({ email: user?.email! }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);


  useEffect(() => {
    if (childIds?.length > 0) {
      dispatch(GetChildSchedules({ childIds: childIds }));
    }

    //fetches settings for schedules
    dispatch(getScheduleSettings());
  }, [dispatch]);

  // console.log("childList:", childList, childIds);

  //#endregion
  
  return (
    <Box className="schedule-style" margin={5}>
      <ScheduleCalendar 
        childIds={childIds}
        childDetailList={childDetailList}
        scheduleSetting={scheduleSetting} 
      />
    </Box>
  );
};

export default ScheduleNew;
