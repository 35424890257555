import React, { useState } from "react";
import { Box, Button, Dialog, Grid, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import ComposeMessage from "../message-form/ComposeMessage";
import "../../Modalblock.scss";
import { useTranslation } from "react-i18next";

interface IMessageHeading {
  // selectedClientId: (Id: string) => string;
  selectedClientId: string;
}

const MessageHeading: React.FC<IMessageHeading> = ({ selectedClientId }) => {
  //translation
  const { t } = useTranslation();

  //used to handle compose form Dialog
  const [openPopUp, setOpenPopUp] = useState<boolean>(false);

  //handle compose button
  const handleClick = () => {
    setOpenPopUp(true);
  };

  return (
    <>
      <div className="listingTable">
        <div className="listingTable__header">
          <Typography className="listingTable__title" gutterBottom variant="h6">
          {t("main.message.message_center")}
          </Typography>
          <Button onClick={() => handleClick()}>{t("main.message.compose")}</Button>
        </div>
      </div>
      {
        <Dialog open={openPopUp} className="Modal-block">
          <Grid>
            <Box className="largeModel__head">
              <Typography variant="h5" className="largeModel__title">
              {t("main.message.compose_new_message")}
              </Typography>
              <Button
                className="largeModel__closeBtn"
                onClick={() => {
                  setOpenPopUp(false);
                }}
              >
                <Close />
              </Button>
            </Box>
          </Grid>
          <ComposeMessage
            setOpenPopUp={setOpenPopUp}
            selectedClientId={selectedClientId}
          />
        </Dialog>
      }
    </>
  );
};

export default MessageHeading;
