import axios from "../axios/axios";
import { AllUserMessage, createMessage } from "../type/message";
import { Response } from "../type/response";

//Get Parent Message list
export const getParentMessage = async (id:string) => {
  try {
    const { data } = await axios.get<Response<AllUserMessage>>(
      `/parentMessage/${id}`
    );
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

//post comments
export const postComments = async (
  messageId: string,
  entityId: string,
  comment: string,
  entityType: string,
  files?: any[]
) => {
  const formData = new FormData();
  formData.append("entityId", entityId);
  formData.append("comment", comment);
  formData.append("entityType", entityType);
  files?.forEach((file) => {
    if (!file.fileKey) {
      formData.append("file", file);
    }
  });
  try {
    const data = await axios.post(`/comment/${messageId}`,
      // comment,
      // entityId,
      // entityType,
      formData
    );

    return data.data;
  } catch (e: any) {
    throw e.response.data;
  }
};

//patch message
export const patchComments = async (id: string[]) => {
  try {
    const data = await axios.patch(`/message`,{id});
    return data;
  } catch (e: any) {
    throw e?.response.data;
  }
};

//post create message
export async function postCreateMessage(payloadData: createMessage, entityId: string, files?: any[]) {
  const formData = new FormData();
  formData.append("subject", payloadData.subject);
  formData.append("message", payloadData.message);
  formData.append("whoCanView", JSON.stringify(payloadData.whoCanView));
  formData.append("type", payloadData.type);
  formData.append("from", payloadData.from);
  formData.append("entityType", "Client");
  files?.forEach((file) => {
    if (!file.fileKey) {
      formData.append("file", file);
    }
  });
  try {
    const { data } = await axios.post(`/message/${entityId}`, formData);

    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
}

export const MessagesSlice = {
  getParentMessage,
  postComments,
  patchComments,
  postCreateMessage,
};
