import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setResponseValue } from "../api-response/api-response";
import formService from "../../../service/form.service";
import { AssessmentFormData } from "../../../constants/form";
import { IAssessmentData } from "../../../type/form";

const initialState: IAssessmentData = {
  ...AssessmentFormData,
};

const assessmentformSlice = createSlice({
  name: "assessmentformSlice",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(GetAssessmentList.fulfilled, (state, action) => {
      return {
        ...state,
        assessmentList: [...action.payload],
      };
    });
    builder.addCase(GetAssessmentById.fulfilled, (state, action) => {
      
      return {
        ...state,
        assessmentform: action.payload,
      };
    });
  },
});

//Get list of child
export const GetAssessmentList = createAsyncThunk(
  "/parent/assessmentList",
  async ({ id }: { id: string }, { getState, dispatch }) => {
    try {
      const { data, status } = await formService.getAssessmentlist(id);
      return data;
    } catch (e: any) {
      // throw e?.response?.data;
      throw dispatch(
        setResponseValue({
          name: "message",
          value: e.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

//Get list of child
export const GetAssessmentById = createAsyncThunk(
  "/parent/assessment/Id",
  async ({ id }: { id: string }, { getState, dispatch }) => {
  
    try {
      const { data, status } = await formService.getAssessmentById(id);
      return data;
    } catch (e: any) {
      // throw e?.response?.data;
      throw dispatch(
        setResponseValue({
          name: "message",
          value: e.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

//Update assessment result
export const UpdateFormResult = createAsyncThunk(
  "/parent/updateFormResult",
  async (
    {
      id,
      result,
      isComplete,
    }: { id: string; result: any; isComplete: boolean },
    { getState, dispatch }
  ) => {
    let res = {
      result,
      isComplete: isComplete,
    };
    try {
      const { data , message } = await formService.updateFormResult(id, res);
      dispatch(setResponseValue({ name: "success", value: true }));
      dispatch(setResponseValue({ name: "message", value: message }));
      return data;
    } catch (e: any) {
      throw dispatch(
        setResponseValue({
          name: "message",
          value: e.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);
// export const { setPageNoIndex } = assessmentformSlice.actions;
export default assessmentformSlice;
