import React, { useEffect } from "react";
import { Box, Grid } from "@mui/material";

import { useAppDispatch } from "../../../redux/store";
import {
  openMessage,
  setTranslation,
} from "../../../redux/features/messages/message-slice";

import { IChildList } from "../../../type/child-info";

import ClientImg from "../../../assets/images/Image-svg/client-large.svg";
import ProviderImg from "../../../assets/images/Image-svg/provider-large-vertical.svg";

interface ChildLists {
  setSelectedClientId: React.Dispatch<React.SetStateAction<string>>;
  setSelectedChild: React.Dispatch<
    React.SetStateAction<IChildList | undefined>
  >;
  selectedChild: IChildList | undefined;
  ChildDataLists: IChildList[];
  isMobileView: boolean;
  setIsMobileView: React.Dispatch<React.SetStateAction<boolean>>;
}

const ChildList: React.FC<ChildLists> = ({
  setSelectedClientId,
  setSelectedChild,
  ChildDataLists,
  selectedChild,
  isMobileView,
  setIsMobileView,
}) => {
  //dispatch
  const dispatch = useAppDispatch();

  //handle tabs data
  const handleTabData = (ID: string) => {
    let data = ChildDataLists.filter((item: IChildList) => {
      return item.id === ID;
    });
    setSelectedChild(data[0]);
    setSelectedClientId(data[0]?.id);
    dispatch(openMessage(null));
    dispatch(setTranslation(null));
  };
  //#end

  useEffect(() => {
    window.addEventListener("resize", () => {
      const width = window.innerWidth;
      if (width <= 886) {
        setIsMobileView(true);
      } else {
        setSelectedChild(ChildDataLists[0]);
        setIsMobileView(false);
      }
    });
    if (window.innerWidth <= 886 && !isMobileView) {
      setIsMobileView(true);
    }
  }, []);

  useEffect(() => {
    if (window?.innerWidth >= 886 && ChildDataLists.length > 0) {
      setSelectedClientId(ChildDataLists[0]?.id);
      setSelectedChild(ChildDataLists[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid item xs={16} md={4}>
      {ChildDataLists.map((item: IChildList) => {
        return (
          <Box>
            <button
              style={{
                display: "flex",
                alignItems: "center",
                position: "relative",
                gap: "1px",
              }}
              onClick={() => handleTabData(item.id)}
              className={
                item.id === selectedChild?.id
                  ? "active messageInternal-tabBlockk"
                  : "messageInternal-tabBlockk"
              }
            >
              <span>
                <p
                  className={
                    item.messageCount > 0 ? "activemsg" : "activemsg visible"
                  }
                ></p>
              </span>
              <img
                src={
                  item.clientProfile.url ||
                  (item.clientType === "Intake" || item.clientType === "Ongoing"
                    ? ClientImg
                    : ProviderImg)
                }
                className="userSmallCircle"
                alt=""
                style={{
                  height: "32px",
                  width: "32px",
                  objectFit: "cover",
                  overflow: "hidden",
                  borderRadius: "50px",
                }}
              />{" "}
              &nbsp;
              {`${item.childFirstName}${" "}${item.childLastName}`}
              <span style={{ position: "absolute", right: "6%" }}>
                {item.messageCount}
              </span>
            </button>
          </Box>
        );
      })}
    </Grid>
  );
};

export default ChildList;
