import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Step,
  StepLabel,
  Stepper,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ClientImg from "../../assets/images/Image-svg/client-large.svg";
import ProviderImg from "../../assets/images/Image-svg/provider-large-vertical.svg";
import moment from "moment";
import {
  AvailabilityTimesForSchedulesBars,
  CancelOptions,
  UpdateOptions,
} from "../../constants/schedule";
import { useDispatch, useSelector } from "react-redux";
import { setResponseValue } from "../../redux/features/api-response/api-response";
import { cancelService, bookService } from "../../service/schedule.service";
import { AppDispatch, RootState } from "../../redux/store";
import {
  NewDataCatalog,
  ParentScheduleSetting,
  RebookingOptionPerDate,
  ReBookingOptionsResult,
  TimeSlot,
} from "../../type/schedule";
import {
  GetChildSchedules,
  GetRebookingProviders,
  clearRebookings,
  getDataCatalog,
} from "../../redux/features/schedules/schedule-slice";
import "./BookingEditModal.scss";
import { IBookingData } from "../../type/client";
import { EmploymentDetails } from "../../type/provider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getXDaysFromDate, tomorrow } from "../../constants/comman";
import Loader from "../../layouts/loader/Loader";
import { useTranslation } from "react-i18next";

//Menu props for the select Mui component or element
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface BookingModalProps {
  pageTitle: string;
  isDelete: boolean;
  isEdit: boolean;
  closePopup: React.Dispatch<React.SetStateAction<boolean>>;
  argsdata: Record<string, any> | undefined;
  fromPlanning: boolean;
  clientBooking: IBookingData[];
  childIds: string[];
}

const DiscoverBookingEditModal: React.FC<BookingModalProps> = ({
  pageTitle,
  isDelete,
  isEdit,
  closePopup,
  argsdata,
  fromPlanning,
  clientBooking,
  childIds,
}) => {
  //#region variable region
  //used to dispatch
  const dispatch: AppDispatch = useDispatch();

  //used to show pop-up
  const [open, setOpen] = useState<boolean>(true);

  //translation
  const { t } = useTranslation();

  //stores schedule setting data
  const scheduleSetting = useSelector<RootState, ParentScheduleSetting>(
    (state) => state.scheduleSlice.parentScheduleSetting
  );

  //stores rebooking options
  const reBookings = useSelector<RootState, ReBookingOptionsResult>(
    (state) => state.scheduleSlice.Rebookings
  );

  //store initial client start time
  const clientStartTime: string = argsdata
    ? moment(argsdata.StartTime).format("hh:mm A")
    : "07:00 AM";

  //store initial client end time
  const clientEndTime: string = argsdata
    ? moment(argsdata.EndTime).format("hh:mm A")
    : "06:00 PM";

  //selected Date for rebooking
  const [selectedDate, setSelectedDate] = React.useState<any>(tomorrow());

  //for loader component
  const [isLoading, setIsLoading] = useState<boolean>(true);

  //service type
  // const [serviceType, setServiceType] = useState<string>(
  //   argsdata ? argsdata.ServiceType : ""
  // );

  //changed start time for client
  // const [startTime, setStartTime] = useState<string>(clientStartTime);

  //changed end time for client
  // const [endTime, setEndTime] = useState<string>(clientEndTime);

  // store client Address
  // const [address, setAddress] = useState<string>(
  //   argsdata && argsdata.Location ? argsdata.Location : ""
  // );

  //store active step for rebooking
  const [activeStep, setActiveStep] = useState<number>(1); //set to 1, no ask, just show option!

  //stores start time for rebooking
  const [startTime, setStartTime] = useState<string>(clientStartTime);

  //stores end Time for Rebooking
  const [endTime, setEndTime] = useState<string>(clientEndTime);

  //stored the repeat service
  const [cancelOption, setCancelOption] = useState<number>(
    argsdata?.cancelOption ? argsdata.cancelOption : 0
  );

  // store who's cancelled
  // const [whoCancelled, setWhoCancelled] = useState<string>(
  //   argsdata?.whoCancelled ? argsdata?.whoCancelled : "Client"
  // );

  // store cancellation reasion
  const [cancellationReason, setCancellationReason] = useState<string>(
    argsdata?.cancelReason ? argsdata?.cancellationReason : ""
  );

  //cancellation data from redux
  const ClientReasonCatalog = useSelector<RootState, NewDataCatalog[]>(
    (state) => state.scheduleSlice.ClientReasonCatalog
  );
  //store all billable data
  const BillableCatalog = useSelector<RootState, NewDataCatalog[]>(
    (state) => state.scheduleSlice.BillableCatalog
  );
  //store all non billable data
  const NonBillableCatalog = useSelector<RootState, NewDataCatalog[]>(
    (state) => state.scheduleSlice.NonBillableCatalog
  );

  //handles appointment error
  const [appointmentError, setAppointmentError] = useState<boolean>(false);

  //handles reason error
  const [reasonError, setReasonError] = useState<boolean>(false);

  //handle selected timeslots
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<
    TimeSlot | undefined
  >(undefined);

  //Returns the drop down values for start and end time
  const getAvailabilityTimes = (index: number): JSX.Element[] => {
    const data: JSX.Element[] = [];
    for (let i = index; i < AvailabilityTimesForSchedulesBars.length; i++) {
      data.push(
        <MenuItem key={i} value={AvailabilityTimesForSchedulesBars[i]}>
          {AvailabilityTimesForSchedulesBars[i]}
        </MenuItem>
      );
    }
    return data;
  };
  //handling the start time
  const setServiceFromTime = (value: string): void => {
    setStartTime(value);
  };

  //handling the end time
  const setServiceToTime = (value: string): void => {
    setEndTime(value);
  };

  // //gets index from time
  const getIndexFromTimeValidation = (val: string): number => {
    for (let i = 0; i < AvailabilityTimesForSchedulesBars.length; i++) {
      if (AvailabilityTimesForSchedulesBars[i] === val) return i;
    }
    return -1;
  };
  //on submitting the form API calling
  const onSubmitRebook = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: string,
    timeSlot: TimeSlot | undefined
  ) => {
    if (timeSlot === undefined) {
      setAppointmentError(true);
      return;
    }
    e.preventDefault();
    dispatch(setResponseValue({ name: "pending", value: true }));
    closePopup(false);
    let bookingData;

    // console.log("isEdit, isDelete:", isEdit, isDelete, argsdata?.bookedClientId);
    try {
      if (isEdit) {
        const day = moment(timeSlot.date).format("dddd");

        let category = "";
        // find category in BillableCatalog
        const billable = BillableCatalog.find(
          (item) => item.conceptValue === argsdata?.ServiceType
        );
        if (billable) {
          category = "Billable";
        } else {
          const nonBillable = NonBillableCatalog.find(
            (item) => item.conceptValue === argsdata?.ServiceType
          );
          if (nonBillable) {
            category = "Non Billable";
          }
        }
        // console.log("category:", category);
        bookingData = {
          //client, provider
          bookedClientId: argsdata?.bookedClientId?.id,
          bookedProviderId: id,

          //schedule
          specificDate: moment(timeSlot.date).toString(), //must pass it as local dtm!!!
          fromTime: clientStartTime, //use same original time
          toTime: clientEndTime, //use same original time
          updateOption: 0, //should be 0
          repeatWorkDays: [day],
          repeatService: 0,
          repeatCustomEnd: null,
          userTodayDate: moment(new Date()).format("ddd YYYY-MM-DD"),

          //same as original one
          serviceId: argsdata?.serviceID,
          serviceType: argsdata?.ServiceType,
          serviceLocation: argsdata?.Location,
          description: argsdata?.description,
          authId: argsdata?.authId,
          authCode: argsdata?.authCode,
          category: category,

          createdBy: argsdata?.bookedClientId.id,
        };
        let { data } = await bookService(bookingData, false);

        //cancel old one
        bookingData = {
          serviceId: argsdata?.serviceID,
          cancelOption: 0, //should be 0 as current
          specificDate: argsdata?.specificDate,
          bookedClientId: argsdata?.bookedClientId?.id,
          bookedProviderId: id,
          whoCancelled: "Client",
          cancellationReason: "Re-schedule",
        };

        let { data: cancelData } = await cancelService(
          bookingData,
          fromPlanning
        );

        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(
          setResponseValue({
            name: "message",
            value: "re-schedule successfully",
          })
        );
      }

      //close popup & update schedule
      closePopup(false);
      dispatch(GetChildSchedules({ childIds: childIds }));
    } catch (e: any) {
      console.log("scheduling error:", e);
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  };

  const onSubmitCancel = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: string
  ) => {
    e.preventDefault();
    dispatch(setResponseValue({ name: "pending", value: true }));
    closePopup(false);
    let bookingData;

    try {
      if (isDelete) {
        bookingData = {
          serviceId: argsdata?.serviceID,
          cancelOption: cancelOption,
          specificDate: argsdata?.specificDate,
          bookedClientId: argsdata ? argsdata.bookedClientId?.id : "",
          bookedProviderId: argsdata?.bookedProviderId?.id,
          whoCancelled: argsdata?.whoCancelled,
          cancellationReason: cancellationReason,
        };

        let { data } = await cancelService(bookingData, fromPlanning);

        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: data?.message }));
      }
      closePopup(false);
      dispatch(GetChildSchedules({ childIds: childIds }));
    } catch (e: any) {
      console.log("scheduling error:", e);
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  };

  useEffect(() => {
    // console.log("reloading...");
    setIsLoading(false);
    dispatch(clearRebookings({}));
    dispatch(getDataCatalog());

    //direct fetch & show options
    setIsLoading(true);
    dispatch(
      GetRebookingProviders({
        bookingId: argsdata?.serviceID,
        cancelRequestBy: "Client",
      })
    );
    setIsLoading(false);

    // }
  }, [dispatch]);

  // console.log("reBookings:", isDelete, isEdit, reBookings);

  return (
    <>
      {isLoading && <Loader isShow={isLoading} />}
      <Dialog
        open={open}
        aria-labelledby="draggable-dialog-title"
        className={
          isDelete || isEdit
            ? "largeModel schedule-detail-model deleteSchedule"
            : "largeModel schedule-detail-model"
        }
      >
        <Box className="largeModel__head">
          <Typography variant="h5" className="largeModel__title">
            {pageTitle}: {argsdata?.bookedClientId?.childFirstName}{" "}
            {argsdata?.bookedClientId?.childLastName}
          </Typography>
          <Button
            className="largeModel__closeBtn"
            onClick={() => {
              closePopup(false);
              setOpen(false);
            }}
          >
            <CloseIcon />
          </Button>
        </Box>
        <DialogContent>
          <Box className="largeModel__body">
            <Box className="bookingModel">
              <Box className="bookingModel__form">
                <Grid container spacing={4}>
                  <Grid item xs={12} className={"fromGroup"}>
                    <Grid>
                      {isEdit && (
                        <div>
                          {activeStep === 1 && (
                            <>
                              <Grid item xs={12}>
                                <p className="detailsCard-part__text">
                                  <b>{t("main.schedule.date_range")}:</b>{" "}
                                  {/* {scheduleSetting?.maxDaysToRebook} */}
                                  {moment
                                    .utc(reBookings.beginDate)
                                    .format("ll")}
                                  {" - "}
                                  {moment.utc(reBookings.endDate).format("ll")}
                                </p>
                                <p className="detailsCard-part__text">
                                  <b>
                                    {t("main.schedule.available_providers")}
                                  </b>
                                  :{" "}
                                  {
                                    reBookings?.options
                                      ?.uniqueLimitProviderIdsCount
                                  }{" "}
                                  (
                                  {scheduleSetting.rebookProviderType.toLowerCase() ===
                                  "same"
                                    ? t("main.schedule.same_provider_checked")
                                    : reBookings.baseProvidersCount +
                                      " " +
                                      t("main.schedule.provider_checked")}
                                  )
                                </p>
                                <p className="detailsCard-part__text">
                                  <b>{t("main.schedule.service")}</b>:{" "}
                                  {argsdata?.ServiceType}
                                </p>
                                {/* <p className="detailsCard-part__text">
                                  {reBookings?.errorMessage?.length > 0 ? reBookings?.errorMessage : ""}
                              </p> */}
                                {appointmentError ? (
                                  <FormHelperText style={{ color: "#d32f2f" }}>
                                    Please select a time slot first.
                                  </FormHelperText>
                                ) : (
                                  ""
                                )}
                              </Grid>

                              {reBookings?.options?.dateOptions?.length ===
                                0 && <p>Searching...</p>}
                              <Box
                                display={"flex"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                sx={{ minHeight: "100px" }}
                                flexDirection={"column"}
                              >
                                {reBookings?.options?.dateOptions?.length > 0 &&
                                  reBookings?.options?.dateOptions?.map(
                                    (
                                      item: RebookingOptionPerDate,
                                      index: number
                                    ) => (
                                      //for each reBookings?.options?.fitBookSlots
                                      // item.fitBookSlots?.map((timeSlot: any) => (
                                      <Card
                                        sx={{
                                          display: "flex",
                                          width: "100%",
                                          justifyContent: "space-between",
                                          marginBottom: "20px",
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            whiteSpace: "normal",
                                          }}
                                        >
                                          <CardContent
                                            sx={{
                                              flex: "1 0 auto",
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            {/* <img
                                              src={
                                                scheduleSetting.showProvider
                                                  ? item.provider
                                                      ?.providerProfile?.url
                                                    ? item.provider
                                                        ?.providerProfile?.url
                                                    : ProviderImg
                                                  : ProviderImg
                                              }
                                              height={45}
                                              className="userSmallCircle"
                                              alt=""
                                            /> */}
                                            <Box>
                                              <Typography
                                                component="div"
                                                variant="h6"
                                              >
                                                {scheduleSetting.showProvider
                                                  ? `${item.provider?.firstName} ${item.provider?.lastName}`
                                                  : `${t(
                                                      "main.schedule.provider"
                                                    )}:${index + 1}`}
                                              </Typography>
                                              {/* <Typography
                                                variant="subtitle2"
                                                color="text.secondary"
                                                component="div"
                                              >
                                                {item.provider?.type}
                                              </Typography> */}
                                            </Box>

                                            <Box>
                                              <Typography
                                                component="div"
                                                variant="h6"
                                                whiteSpace="normal"
                                                minWidth={200}
                                              >
                                                <div>
                                                  {moment(item.date).format(
                                                    "ddd"
                                                  )}
                                                </div>
                                                <div>
                                                  {moment(item.date).format(
                                                    "ll"
                                                  )}
                                                </div>
                                              </Typography>
                                            </Box>

                                            <Box
                                              sx={{
                                                width: 250,
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "10px",
                                                alignItems: "flex-end",
                                              }}
                                            >
                                              <Select
                                                className="demo-simple-select"
                                                labelId="demo-simple-select-label"
                                                value={
                                                  selectedTimeSlot
                                                    ? `${selectedTimeSlot.startTime} - ${selectedTimeSlot.endTime}`
                                                    : ""
                                                }
                                                onChange={(e: any) => {
                                                  // Find the time slot that matches the value selected
                                                  const newSelectedTimeSlot =
                                                    item?.fitBookSlots?.find(
                                                      (timeSlot) =>
                                                        `${timeSlot.startTime} - ${timeSlot.endTime}` ===
                                                        e.target.value
                                                    );
                                                  setSelectedTimeSlot(
                                                    newSelectedTimeSlot
                                                  );
                                                }}
                                              >
                                                {item?.fitBookSlots &&
                                                  item?.fitBookSlots?.map(
                                                    (timeSlot, index) => (
                                                      <MenuItem
                                                        value={`${timeSlot.startTime} - ${timeSlot.endTime}`}
                                                        key={index}
                                                        sx={{
                                                          gap: "10px",
                                                        }}
                                                      >
                                                        {timeSlot.startTime} -{" "}
                                                        {timeSlot.endTime}
                                                      </MenuItem>
                                                    )
                                                  )}
                                              </Select>
                                              {/* {!item.isSlotAvailable && (
                                                <Typography
                                                  variant="subtitle2"
                                                  color="text.secondary"
                                                  component="div"
                                                  sx={{
                                                    color: "#d32f2f",
                                                    fontSize: "14px",
                                                  }}
                                                >
                                                  {t("main.schedule.result")}:{" "}
                                                  {item.errorMessage}
                                                </Typography>
                                              )} */}
                                              {item.isSlotAvailable && (
                                                <Button
                                                  variant="contained"
                                                  sx={{ width: "120px" }}
                                                  onClick={(
                                                    e: React.MouseEvent<
                                                      HTMLButtonElement,
                                                      MouseEvent
                                                    >
                                                  ) => {
                                                    onSubmitRebook(
                                                      e,
                                                      item.provider?.id,
                                                      selectedTimeSlot
                                                    );
                                                  }}
                                                >
                                                  {t("main.schedule.book")}
                                                </Button>
                                              )}
                                            </Box>
                                          </CardContent>
                                        </Box>
                                      </Card>
                                      // ))
                                    )
                                  )}
                              </Box>
                            </>
                          )}
                        </div>
                      )}
                      {isDelete && (
                        <>
                          <Grid item xs={12} md={6}>
                            <InputLabel className="fromLabel">
                              {t("main.schedule.service_type")}
                            </InputLabel>
                            <p className="detailsCard-part__text">
                              {argsdata?.ServiceType}
                            </p>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <InputLabel className="fromLabel">
                              {t("main.schedule.service_time")}
                            </InputLabel>
                            <p className="detailsCard-part__text">
                              {clientStartTime} - {clientEndTime}
                            </p>
                          </Grid>
                          <Grid item xs={12}>
                            <InputLabel className="fromLabel">
                              {t("main.schedule.service_location")}
                            </InputLabel>
                            <p className="detailsCard-part__text">
                              {argsdata?.Location}
                            </p>
                          </Grid>
                        </>
                      )}

                      {/* show cancel Current Event */}
                      {isDelete && (
                        <Grid item xs={12}>
                          <InputLabel className="fromLabel">
                            {t("main.schedule.what_would_cancel")}
                          </InputLabel>
                          <div className="fromGroup-chips">
                            {CancelOptions.map(
                              (item: string, index: number) =>
                                index === 0 && (
                                  <Card
                                    className={
                                      index === cancelOption
                                        ? "fromGroup-chip-active"
                                        : ""
                                    }
                                    onClick={() => setCancelOption(index)}
                                    key={item}
                                  >
                                    {item}
                                  </Card>
                                )
                            )}
                          </div>
                        </Grid>
                      )}

                      {/* show cancel event + cancel reason */}
                      {isDelete && activeStep === 0 && (
                        <>
                          {/* show cancel reason */}
                          <Grid item xs={12}>
                            <InputLabel className="fromLabel">
                              {t("main.schedule.cancel_reason")}
                            </InputLabel>
                            <div className="fromGroup-chips">
                              <Select
                                disabled={
                                  !scheduleSetting.serviceType.includes(
                                    argsdata?.ServiceType
                                  )
                                }
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label={t("main.schedule.reason_cancel_label")}
                                MenuProps={MenuProps}
                                value={cancellationReason}
                                onChange={(e: SelectChangeEvent<string>) => {
                                  setCancellationReason(e.target.value);
                                  setReasonError(false);
                                }}
                              >
                                {ClientReasonCatalog?.map((item: any) => {
                                  return (
                                    <MenuItem
                                      key={item.id}
                                      value={item.conceptValue}
                                    >
                                      {item.conceptValue}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </div>
                            {reasonError ? (
                              <FormHelperText style={{ color: "#d32f2f" }}>
                                {t("main.schedule.cancel_reason_error")}
                              </FormHelperText>
                            ) : (
                              ""
                            )}
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </DialogContent>
        {isDelete && (
          <DialogActions>
            <Box className="largeModel__footer">
              <Button
                disabled={
                  !isEdit &&
                  !scheduleSetting.serviceType.includes(argsdata?.ServiceType)
                }
                className={`button ${
                  !isEdit &&
                  !scheduleSetting.serviceType.includes(argsdata?.ServiceType)
                    ? "disabled"
                    : ""
                }`}
                onClick={(
                  e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                ) => {
                  onSubmitCancel(e, "");
                }}
              >
                {t("main.schedule.cancel_booking")}
              </Button>
            </Box>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};

export default DiscoverBookingEditModal;
