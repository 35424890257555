import { IBookingData } from "../type/client";

//return filtered schedules based on either clientIds + providerIds, data range, whether to include cancel or not.
export const filterSchedulesByIds = (
  booking: any,

  clientIds: string[],
  providerIds: string[],
  filterFromDate: Date,
  filterToDate: Date,
  includeCancel: boolean
) => {
  let results: IBookingData[] = [];
  if (booking) {
    results = booking
    .filter(
      (schedule: IBookingData) =>
        (clientIds?.includes(schedule.bookedClientId?.id) ||
          providerIds?.includes(schedule.bookedProviderId?.id)) &&
        new Date(schedule.specificDate).getTime() >= filterFromDate.getTime() &&
        new Date(schedule.specificDate).getTime() <= filterToDate.getTime() &&
        (includeCancel ||
          (!includeCancel && schedule.status.toUpperCase() !== "CANCELLED"))
    )
    .sort(
      (a: any, b: any) =>
        new Date(a.specificDate).getTime() - new Date(b.specificDate).getTime()
    );
  }
  return results;
};