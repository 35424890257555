import axios from "../axios/axios";
import { orgData } from "../constants/organization";
import { SingleResponse } from "../type/response";

//Get Organization
export const getOrganization = async () => {
  try {
    //call api
    const { data } = await axios.get<SingleResponse<orgData>>("/parent/organization");
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};
