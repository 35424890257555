import {
  IChildDetails,
  IChildList,
  IClientSchedule,
  IFormData,
  IForms,
  IFormsAllData,
  IOrganizationOptions,
} from "../type/child-info";

//Child List Intial Stage
export const ChildList: IChildList = {
  id: "",
  parentFirstName: "",
  parentLastName: "",
  childFirstName: "",
  childLastName: "",
  childDateOfBirth: "",
  childGender: "",
  childLanguages: [],
  businessUnit: "",
  concerns: "",
  hearAboutUs: "",
  insuranceProvider: "",
  clientType: "Intake",
  messageCount:0,
  onTrackStage: "",
  concernedStage: [],
  intakeStage: "New",
  isEdit: false,
  clientProfile: {},
  createdAt: "",
  files: [],
};

//intial stage OrganizationOptions
// export const organizationOptions: IorganizationOptions = {
//   allowToCompleteCustomIntakeForm: false,
//   allowToUploadBackInsuranceCard: false,
//   allowToUploadFrontInsuranceCard: false,
//   // formURL: "",
//   // formEmbeddedCode: "",
//   form: [
//     // {
//     //   id: "",
//     //   name: "",
//     //   url: "",
//     //   embeddedCode: "",
//     //   createdAt: "",
//     //   updatedAt: "",
//     //   isJot: false,
//     //   isComplete:false,
//     // },
//   ],
//   createdAt: "",
//   id: "",
//   orgLogo: {
//     containerName: "",
//     fileName: "",
//     key: "",
//     url: "",
//   },
//   orgName: "",
//   orgUrl: "",
//   updatedAt: "",
// };

export const form: IForms = {
  formType: "",
  url: null,
  formCategory: "",
  createdAt: "",
  updatedAt: "",
};

export const formData: IFormData = {
  status: "",
  form: form,
  id: "",
};

export const AllformData: IFormsAllData = {
  AssessmentForm: [formData],
  IntakeClientForm: [formData],
  IntakePacketForm: [formData],
};

export const organizationOptions: IOrganizationOptions = {
  allowToCompleteCustomIntakeForm: null,
  allowToSendEmailToAdminSchedule: null,
  allowToSendEmailToClientSchedule: null,
  allowToSendEmailToProviderSchedule: null,
  allowToSendReminderSchedule: null,
  allowToSendSMSToClientSchedule: null,
  allowToUploadBackInsuranceCard: null,
  allowToUploadFrontInsuranceCard: null,
  createdAt: "",
  form: AllformData,
  descope_tenantId: "",
  id: "",
  orgLogo: {
    containerName: "",
    fileName: "",
    key: "",
    url: "",
  },
  orgName: "",
  orgUrl: "",
  updatedAt: "",
};

//client details initial stage
export const client: IChildList = {
  id: "",
  parentFirstName: "",
  parentLastName: "",
  childFirstName: "",
  childLastName: "",
  childDateOfBirth: "",
  childGender: "",
  childLanguages: [],
  businessUnit: "",
  concerns: "",
  hearAboutUs: "",
  insuranceProvider: "",
  messageCount:0,
  clientType: "Intake",
  onTrackStage: "",
  concernedStage: [],
  intakeStage: "New",
  isEdit: false,
  clientProfile: {},
  createdAt: "",
  files: [],
};

//client details
export const DefaultChildDetail: IChildDetails = {
  client,
  organizationOptions,
};

//client schedules initial stage
export const clientschedule: IClientSchedule = {
  id: "",
  descope_tenantId: "",
  status: "",
  specificDate: "",
  repeatWorkDays: "",
  repeatEndsOn: "",
  repeatService: 1,
  repeatOn: [],
  customDate: "",
  serviceType: "",
  serviceLocation: "",
  description: "",
  whoCancelled: null,
  cancellationReason: null,
  createdBy: "",
  fromTime: "",
  toTime: "",
  authId: "",
  authCode: "",
  createdAt: "",
  updatedAt: "",
};

// export const clientschedulelist: IScheduleList[] = {
//   clientschedulelist: [],
// };
