import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import { useLoginWithRedirect } from "@frontegg/react";
import { Descope } from "@descope/react-sdk";

const Login: React.FC = () => {
  // const loginWithRedirect = useLoginWithRedirect();
  const naviate = useNavigate();
  const onLoginSuccess = () => {
    naviate("/home");
  };

  // useEffect(() => {
  //   loginWithRedirect();
  // }, [loginWithRedirect]);

  return (
    <Descope
      flowId="otp-sign-in-parent"
      onSuccess={onLoginSuccess}
      onError={(e) => console.log("Could not log in!", e)}
    />
  );
};

export default Login;
