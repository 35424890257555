

// Define a function to shorten the file name
export const shortenFileName = (fileName:string, maxLength:number) => {
  if (fileName.length <= maxLength) {
    return fileName;
  }

  const truncatedName = fileName.substring(0, maxLength - 3) + "...";
  return truncatedName;
};

//comapare dates
export const formattedDate = (item: any): string => {
  let FullDate = item.split("-");
  let Year = parseInt(FullDate[0]);
  let Month = parseInt(FullDate[1]);
  let Date = parseInt(FullDate[2]);

  return ` ${Year}${"/"}${Month}${"/"}${Date}`;
};

//return a tomorrow as Date
export const tomorrow = () => {
  let date = new Date();
  date.setDate(date.getDate() + 1);
  return date;
};

//get X days from a date
export const getXDaysFromDate = (fromDate: Date, days: number) => {
  let date = fromDate;
  date.setDate(date.getDate() + days);
  return date;
};