import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import clientSlice from "./features/client-main/client-list-slice";
import responseSlice from "./features/api-response/api-response";
import parentmessageSlice from "./features/messages/message-slice";
import currentTenantSlice from "./features/current-tenant/current-tenant-slice";
import assessmentformSlice from "./features/form/form-slice";
import organizationSlice from "./features/organization/organization-slice";
import scheduleSlice from "./features/schedules/schedule-slice";
import clientIntakeSlice from "./features/client-intake-stage/client-intake-slice";

export const store = configureStore({
  reducer: {
    clientSlice: clientSlice.reducer,
    messagesSlice: parentmessageSlice.reducer,
    responseSlice: responseSlice.reducer,
    currentTenantSlice: currentTenantSlice.reducer,
    assessmentformSlice: assessmentformSlice.reducer,
    organizationSlice: organizationSlice.reducer,
    scheduleSlice: scheduleSlice.reducer,
    clientIntakeSlice: clientIntakeSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
// export type StoreType = ReturnType<typeof store.getState>;
// export type StoreDispatch = typeof store.dispatch;
// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
