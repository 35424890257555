import { ClientBasicDetails, IClientDetails } from "./client";
import { EmploymentDetails } from "./provider";

export interface BookedSchedule {
    bookedClientId: ClientBasicDetails;
    bookedProviderId: EmploymentDetails;
    createdBy: string;
    createdAt: string;
    fromTime: string;
    toTime: string;
    id: string;
    repeatEndsOn: string;
    //repeatOn: string[];
    repeatCustomEnd: string;
    repeatService: number;
    repeatWorkDays: string;
    specificDate: Date;
    status: string;
    updatedAt: string;
    serviceType: string;
    serviceLocation: string;
    description: string;
    whoCancelled: string;
    cancellationReason: string;
    authId: string;
    authCode: string;
  }

  export interface ParentScheduleSetting {
    allowParentToCancel: boolean;
    allowParentToRebook: boolean;
    maxBookingToCancel: number;
    maxHrsToCancle: number;
    maxProviderToRebook: number;
    rebookProviderType: string;
    serviceType: string[];
    showProviderName: boolean;
    showProviderType: boolean;
    maxDaysToRebook: number;
    minHrsToCancel: number;
    showProvider: boolean;
  }


export enum ServiceColor {
  Assessment = "Assessment",
  DirectTherapy = "Direct Therapy",
  ParentTraining = "Parent Training",
  ProtocolModification = "Protocol modification",
}


export interface NewDataCatalog {
  id: string;
  catalogName: string;
  conceptValue: string;
  description: string;
  isActive: boolean;
  data?: {
    insurance: string;
    minsPerUnit: number;
    unitsPerDay: number;
  };
  updatedAt: string;
  updatedBy: {
    firstName: string;
    lastName: string;
    profilePictureUrl: string;
  };
}

export interface RebookingOptionPerProvider {
  provider: EmploymentDetails;
  errorMessage: string;
  isSlotAvailable: boolean;
  fitBookSlots: TimeSlot[];
  notFitBookSlots: TimeSlot[];
}

export interface RebookingOptionPerDate {
  date: string;
  dayOfWeek: string;
  provider: EmploymentDetails;

  isSlotAvailable: boolean;
  errorMessage: string;
  fitBookSlots: TimeSlot[];
  notFitBookSlots: TimeSlot[];
}

export interface TimeSlot {
  date: string;
  dateOfWeek: string;
  startTime: string;
  endTime: string;
}


export interface ReBookingOptionsResult {
  errorMessage: string; //cancel error or other errors before rebooking
  baseProvidersCount: number;
  beginDate: string;
  endDate: string;
  authStartDate: string;
  authEndDate: string;
  options : {
    // providerOptions: RebookingOptionPerProvider[]; //show rebookingOption based on provider & date
    dateOptions: RebookingOptionPerDate[]; //show rebookingOption based on date & provider
    uniqueProviderIdsCount: number, //total unique provider ids count
    uniqueLimitProviderIdsCount: number, //total unique limit provider ids count
    uniqueDatesCount: number, //total unique dates count
  }
}

export interface IScheduleDatalist {
  clientsDetails: IClientDetails[];
  parentScheduleSetting: ParentScheduleSetting;
  Rebookings: ReBookingOptionsResult;
  ClientReasonCatalog: NewDataCatalog[];
  BillableCatalog: NewDataCatalog[];
  NonBillableCatalog: NewDataCatalog[];

}
