import axios from "../axios/axios";
import { setResponseValue } from "../redux/features/api-response/api-response";
import { IChildDetails, IChildList, IClientSchedule } from "../type/child-info";
import { UpdateResponse } from "../type/response";

interface Common {
  status: number;
  message: string;
  error: string;
}

interface Response<T> extends Common {
  data: T[];
}

interface SingleResponse<T> extends Common {
  data: T;
}

//Get Child List
export const getChildsByemail = async (email: string) => {
  try {
    const { data } = await axios.patch<Response<IChildList>>(
      `/parent/getChildList`,
      { email }
    );
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  } 
};

//Get child details
export const getChildById = async (clientId: string) => {
  try {
    const { data } = await axios.get<SingleResponse<IChildDetails>>(
      `/parent/child/${clientId}`
    );
 
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  } 
};

//Get Child's Schedules
export const getChildScheduleById = async (clientId: string) => {
  try {
    const { data } = await axios.get<Response<IClientSchedule>>(
      `/parent/child/${clientId}/getSchedules`
    );
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  } 
};

// get Client Files by id 
export async function getClientFilesById(clientId: string) {
  try {
    const category = "Intake";
    const { data } = await axios.get(`/files?category=${category}&entityId=${clientId}`);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
}

//patch Imtake form
export const patchIntakeformstatusById = async (formId: string) => {

  try {
    const { data } = await axios.patch<Response<IClientSchedule>>(
      `/organization/business/new/form/${formId}`
    );

    return data;
  } catch (e: any) {
    throw e?.response?.data;
  } 
};

// post File api 
export const postClientFiles = async (
  category: string,
  type: string,
  model: string,
  modelId: string,
  files: any[]
) => {
  const formData = new FormData();
  formData.append("category", category);
  formData.append("type", type);
  formData.append("model", model);
  formData.append("modelId", modelId);
  formData.append("entityId", modelId);
  files.forEach((file) => {
    if (!file.fileKey) {
      formData.append("file", file);
    }
  });
  try {
    const { data } = await axios.post<UpdateResponse>(
      "/file/upload-files",
      formData
    );
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

export async function getAllClientIntakeStage() {
  try {
    const { data } = await axios.get("/board/intake");
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
}

const ClientService = {
  getChildById,
  getChildsByemail,
  getChildScheduleById,
  getClientFilesById,
  postClientFiles,
  patchIntakeformstatusById,
  getAllClientIntakeStage
};

export default ClientService;
