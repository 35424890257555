import {
  AllUserMessage,
  IComments,
  UserMessages,
} from "../type/message";

//category
export const Category = ["External", "Updates"];

// Audience
export const Audience = ["Client", "Provider"];

export const comments: IComments = {
  createdAt: "",
  fromUser: {
    firstName: "",
    lastName: "",
    email: "",
    profilePictureUrl: "",
  },
  fromName: "",
  comment: "",
  files: [],
};

export const profile = {
  url: "",
  key: "",
  containerName: "",
  fileName: "",
  fileId: "",
};

export const allUserMessage: AllUserMessage = {
  id: " ",
  subject: " ",
  message: " ",
  comments: [],
  entityId: " ",
  whoCanView: [],
  seenUserId: [],
  type: " ",
  user: {
    name: " ",
    profile: profile,
    userType: "",
  },
  createdOn: " ",
  updatedOn: " ",
  files: [],
};

const { user, ...UpdatedMessage } = allUserMessage;

export const AllMessagesInfo: UserMessages = {
  AllMessages: [allUserMessage],
  NewMessages: UpdatedMessage,
  TranslatedMessages: null,
  openedMessage: null
};

export const isHtml = RegExp.prototype.test.bind(/(<([^>]+)>)/i);

export function htmlToPlainText(html: string) {
  // Remove HTML tags using a regular expression
  const plainText = html.replace(/<\/?[^>]+(>|$)/g, " ");
  return plainText;
}
