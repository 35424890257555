import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Grid,
  Paper,
  InputLabel,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useSession, useUser, useDescope } from "@descope/react-sdk";

import { Audience, Category } from "../../../constants/message";
import "./composeMessage.scss";
// import { useAuth } from "@frontegg/react";
import { PostCreateMessage } from "../../../redux/features/messages/message-slice";
import { useAppDispatch } from "../../../redux/store";
import { useTranslation } from "react-i18next";
import FileUpload from "react-material-file-upload";
import DeleteIcon from "@mui/icons-material/Delete";

// eslint-disable-next-line
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

interface IComposeMessage {
  setOpenPopUp: React.Dispatch<React.SetStateAction<boolean>>;
  selectedClientId: string;
}

const ComposeMessage: React.FC<IComposeMessage> = ({
  setOpenPopUp,
  selectedClientId,
}) => {
  //#region variable region

  //dispatch
  const dispatch = useAppDispatch();

  const { user, isUserLoading } = useUser();

  //translation
  const { t } = useTranslation();

  //used to subject name
  const [subjects, setSubject] = useState<string>("");

  //used to store message body
  const [textfields, setTextField] = useState<string>("");

  //stores files data
  const [newFiles, setNewFiles] = useState<any[]>([]);

  //handle errors
  const [,setHasError] = useState<boolean>(false);

  //#endregion

  //#region useEffect region

  //#endregion
  return (
    <>
      <div className="stepperForm__inner p-30px message-templateBclobk">
        <Grid container spacing={3}>
          <Grid item xs={2} md={2}>
            <Box className="fromGroup">
              <InputLabel className="fromLabel">
                {t("main.message.subject")}:
              </InputLabel>
            </Box>
          </Grid>
          <Grid item xs={10} md={10}>
            <Box className="fromGroup">
              <TextField
                id="outlined-basic"
                label=""
                variant="outlined"
                placeholder={t("main.message.enter_subject")}
                value={subjects}
                onChange={(e) => {
                  setSubject(e.target.value);
                }}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={12}>
            <Box className="commentField">
              <TextareaAutosize
                value={textfields}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                  setTextField(e.target.value);
                }}
                placeholder={t("main.message.enter_body")}
                minRows={10}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={12}>
            Attachments (up to 5, max size 5MB):
            <Grid item xs={12} className="fileUploadBlock">
              <FileUpload
                maxFiles={5}
                maxSize={5000000}
                multiple
                value={[]}
                onChange={(e: File[]) => {
                  setHasError(false);
                  if (newFiles.length + e.length <= 5) {
                    setHasError(false);
                    setNewFiles([...newFiles, ...e]);
                  } else {
                    setHasError(true);
                  }
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div className="uploadProfile__img selectedIn-fileBox">
              {newFiles &&
                newFiles.map((file, index) => {
                  return (
                    <div className="selectedIn-file">
                      <Typography>{file.name}</Typography>
                      <span>
                        <DeleteIcon
                          onClick={() => {
                            setNewFiles(
                              newFiles.filter(
                                (_, fileIndex) => fileIndex !== index
                              )
                            );
                          }}
                        />
                      </span>
                    </div>
                  );
                })}
            </div>
          </Grid>
        </Grid>
      </div>
      <Box className="stepperForm__footer b-0">
        <Box sx={{ flex: "1 1 auto" }} />
        <Button
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e.preventDefault();

            if (user?.name !== undefined) {
              dispatch(
                PostCreateMessage({
                  entityId: selectedClientId,
                  subject: subjects,
                  message: textfields,
                  whoCanView: ["admin", "parent"],
                  type: "external",
                  from: user?.name,
                  entityType: "Client",
                  files: newFiles ? newFiles : [],
                })
              );
            }
            setOpenPopUp(false);
          }}
          className="button"
        >
          {t("main.message.send_now")}
        </Button>
      </Box>
    </>
  );
};

export default ComposeMessage;
