import { EmploymentDetails } from "../type/provider";
import { ParentScheduleSetting, ReBookingOptionsResult } from "../type/schedule";

// convert Time format 12 to 24
export const convertTime12to24 = (time12h: any) => {
  const [time, modifier] = time12h?.split(" ");

  let [hours, minutes] = time?.split(":");

  if (hours === "12") {
    hours = "00";
  }

  if (modifier === "PM") {
    hours = parseInt(hours, 10) + 12;
  }

  return `${hours}:${minutes}:00`;
};

//schedules settings
export const DefaultParentScheduleSetting: ParentScheduleSetting = {
  allowParentToCancel: false,
  allowParentToRebook: false,
  maxBookingToCancel: 1,
  maxHrsToCancle: 0,
  maxProviderToRebook: 1,
  rebookProviderType: "",
  serviceType: [],
  showProviderName: false,
  showProviderType: false,
  maxDaysToRebook: 0,
  minHrsToCancel: 0,
  showProvider: false,
};

//
export const DefaultEmployementDetails : EmploymentDetails = {
  id: "",
  descope_tenantId: "",
  firstName: "",
  lastName: "",
  businessUnit: [],
  employmentStatus: "",
  languages: [],
  type: "",
  specialities: [],
  providerType: "",
  onBoardingStage: "",
  checklist: [],
  files: [],
  providerProfile: {url: ""},
  isSupervisor: false,
  createdAt: "",
  updatedAt: ""

}

export const DefaultRebookingOptionsResult: ReBookingOptionsResult = {
  errorMessage: "",
  baseProvidersCount: 0,
  beginDate: "",
  endDate: "",
  authStartDate: "",
  authEndDate: "",
  options: {
    // providerOptions: [],
    dateOptions: [],
    uniqueProviderIdsCount: 0,
    uniqueLimitProviderIdsCount: 0,
    uniqueDatesCount: 0,
  }
}

export const CancelOptions: string[] = [
  "Current Event",
  "Current & Future Events",
];

export const UpdateOptions: string[] = [
  "Current Event",
  "Current & Future Events",
]

export const WhoCancelled: string[] = ["Client", "Provider"];

export const AvailabilityTimesForSchedulesBars: string[] = [
  "06:00 AM",
  "06:15 AM",
  "06:30 AM",
  "06:45 AM",
  "07:00 AM",
  "07:15 AM",
  "07:30 AM",
  "07:45 AM",
  "08:00 AM",
  "08:15 AM",
  "08:30 AM",
  "08:45 AM",
  "09:00 AM",
  "09:15 AM",
  "09:30 AM",
  "09:45 AM",
  "10:00 AM",
  "10:15 AM",
  "10:30 AM",
  "10:45 AM",
  "11:00 AM",
  "11:15 AM",
  "11:30 AM",
  "11:45 AM",
  "12:00 PM",
  "12:15 PM",
  "12:30 PM",
  "12:45 PM",
  "01:00 PM",
  "01:15 PM",
  "01:30 PM",
  "01:45 PM",
  "02:00 PM",
  "02:15 PM",
  "02:30 PM",
  "02:45 PM",
  "03:00 PM",
  "03:15 PM",
  "03:30 PM",
  "03:45 PM",
  "04:00 PM",
  "04:15 PM",
  "04:30 PM",
  "04:45 PM",
  "05:00 PM",
  "05:15 PM",
  "05:30 PM",
  "05:45 PM",
  "06:00 PM",
  "06:15 PM",
  "06:30 PM",
  "06:45 PM",
  "07:00 PM",
  "07:15 PM",
  "07:30 PM",
  "07:45 PM",
  "08:00 PM",
  "08:15 PM",
  "08:30 PM",
  "08:45 PM",
  "09:00 PM",
  "09:15 PM",
  "09:30 PM",
  "09:45 PM",
  "10:00 PM",
  "10:15 PM",
  "10:30 PM",
  "10:45 PM",
  "11:00 PM",
  "11:15 PM",
  "11:30 PM",
  "11:45 PM",
  "12:00 AM",
];
