import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { orgData, orgInit } from "../../../constants/organization";
import { getOrganization } from "../../../service/organization.service";

const organizationSlice = createSlice({
  name: "organizationSlice",
  initialState: orgInit,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getOrgInfo.fulfilled,
      (state, action: PayloadAction<orgData | undefined>) => {
        if (action.payload) {
          return {
            ...state,
            orgData: {
              ...action.payload,
            },
          };
        }
        return state;
      }
    );
  },
});

export const getOrgInfo = createAsyncThunk(
  "organizationSlice/getOrgInfo",
  async () => {
    try {
      const { status, data } = await getOrganization();
      // console.log({ status, data });
      if (status) {
        return data;
      }
    } catch (e) {
      console.log(e);
    }
  }
);

export default organizationSlice;
