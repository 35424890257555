import "./App.css";
import "./styles.scss";
import { Routes, Route } from "react-router-dom";
import PrivateRoute from "./routes/private.route";
import Documents from "./pages/documents/documents-main";
import PublicRoute from "./routes/public.route";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
// import { useAuth } from "@frontegg/react";

import Login from "./pages/login/login";
import HomePage from "./pages/home/home-main";
// import Schedule from "./_deprecated/schedule-main";
import Center from "./pages/messageCenter/center";
import Redirector from "./pages/Redirector/Redirector";
import Message from "./pages/Messages/Message";
import Assessment from "./pages/assessment/Assessment";
import SurveyComponent from "./pages/assessment/SurveyComponent";
import AssessmentMain from "./pages/assessment/AssessmentMain";
import Gleap from "gleap";
import ScheduleNew from "./pages/schedule/ScheduleNew";

function App() {
  // const { isLoading, user, isAuthenticated } = useAuth();
  const location = useLocation();

  return (
    <Routes>
      <Route
        element={
          <PrivateRoute>
            <HomePage />
          </PrivateRoute>
        }
        path={"/home"}
      />
      <Route
        element={
          <PrivateRoute>
            <HomePage />
          </PrivateRoute>
        }
        path={"/"}
      />

      <Route
        element={
          <PrivateRoute>
            <Center />
          </PrivateRoute>
        }
        path={"/center"}
      />

      <Route
        element={
          <PrivateRoute>
            <Documents />
          </PrivateRoute>
        }
        path={"/documents"}
      />

      <Route
        element={
          <PrivateRoute>
            <Message />
          </PrivateRoute>
        }
        path={"/messages"}
      />

      <Route
        element={
          <PrivateRoute>
            {/* <Schedule /> */}
            <ScheduleNew />
          </PrivateRoute>
        }
        path={"/schedule"}
      />
      <Route
        element={
          <PrivateRoute>
            {/* <Assessment /> */}
            {/* <SurveyComponent/> */}
            <AssessmentMain />
          </PrivateRoute>
        }
        path={"/assessment"}
      />
      <Route
        element={
          <PublicRoute>
            <Redirector />
          </PublicRoute>
        }
        path={"/redirect"}
      ></Route>

      <Route
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
        path="/login"
      />
    </Routes>
  );
}

export default App;
