
//export Intake stage used main app
//export Intake stage used in the main app
export const InTakeStageProcess = [
  { eng: "New", Spanish: "Nuevo", Chinese: "新" },
  { eng: "Benefit Check in Process", Spanish: "Verificación de Beneficios en Proceso", Chinese: "福利核查中" },
  { eng: "Pending Client Paperwork", Spanish: "Papelería del Cliente Pendiente", Chinese: "待处理的客户文书" },
  { eng: "Initial Auth Request in Process", Spanish: "Solicitud de Autorización Inicial en Proceso", Chinese: "初始授权请求处理中" },
  { eng: "Initial Auth Request Received", Spanish: "Solicitud de Autorización Inicial Recibida", Chinese: "已收到初始授权请求" },
  { eng: "Clinical - Scheduling/Completing Assessment", Spanish: "Clínico - Programando/Completando Evaluación", Chinese: "临床 - 安排/完成评估" },
  { eng: "Clinical - Assessment Complete", Spanish: "Clínico - Evaluación Completa", Chinese: "临床 - 评估完成" },
  { eng: "Direct Service Auth Request in Process", Spanish: "Solicitud de Autorización de Servicio Directo en Proceso", Chinese: "直接服务授权请求处理中" },
  { eng: "Direct Service Auth Request Received", Spanish: "Solicitud de Autorización de Servicio Directo Recibida", Chinese: "已收到直接服务授权请求" },
  { eng: "Scheduling", Spanish: "Programación", Chinese: "安排日程" },
  { eng: "Intake Complete", Spanish: "Ingreso Completo", Chinese: "入职完成" }
];

//export ongoing process
export const OngoingProcess = [
  { eng: "Ongoing Service Started", Spanish: "Servicio en Curso Iniciado", Chinese: "服务已启动" },
  { eng: "Authorization Renewal Requested", Spanish: "Renovación de Autorización Solicitada", Chinese: "请求授权续期" },
  { eng: "Authorization Renewal Approved", Spanish: "Renovación de Autorización Aprobada", Chinese: "授权续期已批准" }
];
//export Intake stage 
export const IntakeProcess = [
  "Benefits Verification",
  "New Client Paperwork",
  "Initial Assessment Auth Request",
  "Schedule/Complete Assessment",
  "Direct Service Auth Request",
  "Confirm Scheduling",
  "Begin Direct Service",
];
