// import { useAuth } from "@frontegg/react";
import React from "react";
import { Navigate } from "react-router-dom";
import { useSession } from "@descope/react-sdk";

const PublicRoute: React.FC<{ children: any }> = ({ children }) => {
  // const { user, isAuthenticated } = useAuth();
  const { isAuthenticated, isSessionLoading } = useSession();
  // const redirectPath = sessionStorage.getItem("_redirectpath") || "/";
  //console.log("isAuthenticated, path: ", isAuthenticated, redirectPath);

  return isAuthenticated ? <Navigate to={"/"} /> : children;
};

export default PublicRoute;
