import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import { IChildList, IFile } from "../../type/child-info";
import {
  getChildDetails,
  getClientFilesById,
} from "../../redux/features/client-main/client-list-slice";
import { IHashObj } from "../../type/commen";
import ClientService from "../../service/client.service";
import FileUpload from "react-material-file-upload";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { setResponseValue } from "../../redux/features/api-response/api-response";
import { ReactComponent as PDF } from "../../assets/icons/pdfIcon.svg";
import { ReactComponent as Dot } from "../../assets/icons/dots.svg";
import { ReactComponent as UploadIcon } from "../../assets/images/uploadIcon-White.svg";
import "./documents.scss";
import moment from "moment";
import { shortenFileName } from "../../constants/comman";
import { useTranslation } from "react-i18next";

interface IClientDocumentsComponents {
  ChildData: IChildList;
}
const DocumentSubPage: React.FC<IClientDocumentsComponents> = ({
  ChildData,
}) => {
  //#region variable region

  //dispatch
  const dispatch = useAppDispatch();

  //translation
  const { t } = useTranslation();

  const [files, setFiles] = useState<File[]>([]);

  const [fileUploaded, setFileUploaded] = useState<boolean>(false);

  // store Child Details
  const currentChild = useAppSelector(
    (state) => state.clientSlice.currentChild
  );

  const clientFiles = useAppSelector((state) => state.clientSlice.clientFiles);

  const sortedClientFiles = [...clientFiles]?.sort((a: IFile, b: IFile) => {
    return (new Date(b.updatedAt) as any) - (new Date(a.updatedAt) as any);
  });

  //#endregion

  //#region methods region

  // file submit handler
  const uploadDocument = async (clientId: any, newFiles: any[]) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { status, message } = await ClientService.postClientFiles(
        "Intake",
        "Client",
        "Client",
        clientId,
        newFiles
      );

      if (status) {
        setFileUploaded(true);

        dispatch(getClientFilesById({ clientId }));
        dispatch(getChildDetails({ id: ChildData?.id }));
        // dispatch(setClientFilesDataChange({}));
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  };

  //handle to download pdf
  const onClickPdf = (item: any) => {
    const link = document.createElement("a");
    link.href = item.fileUrl;
    link.download = item.fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  //handle open new Tab
  const NewTab = (link: any) => {
    window.open(link, "_blank");
  };

  //#endregion

  //#region UseEffect region

  //re-fetch Childdetails and Files
  useEffect(() => {
    if (ChildData) {
      dispatch(getChildDetails({ id: ChildData?.id }));
      dispatch(getClientFilesById({ clientId: ChildData?.id }));
    }
  }, [dispatch, ChildData]);

  useEffect(() => {
    if (fileUploaded) {
      dispatch(getClientFilesById({ clientId: ChildData?.id }));
    }
  }, [dispatch, ChildData?.id, fileUploaded]);

  //#endregion

  return (
    <div className="tabData">
      {/* remove forms section, changed by Hui!!!
      <Box className="uploadBlock">
        <Typography className="uploadBlock__title">Forms</Typography>
        <div className="fileCardsModule">
          <Box>
            <div className="formCard">
              <div className="formCard__img">
                <PDF height={"98px"} width={"98px"} />
                <span className="formCard__moreBtn"></span>
              </div>
              <div className="formCard__btn">
                <span
                  onClick={() =>
                    NewTab(
                      currentChild?.organizationOptions.form[0].url
                    )
                  }
                >
            
                  {
                    currentChild?.organizationOptions?.form[0].name
                  }
                </span>
              </div> 
            </div>
          </Box>
        </div>
      </Box> */}
      <Box className="uploadBlock" mt={0}>
        <div className="TableBlock filesTable">
          <Box textAlign={"right"}>
            <button className="uploadCoustomBtn">
              <UploadIcon />
              {t("main.upload")}
              <FileUpload
                multiple
                value={files}
                onChange={(e: File[]) => {
                  setFiles([...files, ...e]);
                  uploadDocument(ChildData?.id, [...e]);
                }}
              />
            </button>
          </Box>
          <TableContainer className="container">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell> {t("main.files")}</TableCell>
                  <TableCell> {t("main.documents.submitted_on")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedClientFiles?.map((item, index: number) => {
                  return (
                    <TableRow key={index}>
                      <TableCell
                        onClick={() => {
                          onClickPdf(item);
                        }}
                        style={{ whiteSpace: "unset" }}
                      >
                        <div className="fileListItem">
                          <div
                            className="fileListItem__heading"
                            // style={{ wordBreak: "break-all" }}
                          >
                            <PDF />
                            <Typography className="fileListItem__name">
                              {item.fileName.split("_$SPLIT$_")[1] === undefined
                                ? item.fileName
                                : item.fileName.split("_$SPLIT$_")[1]}
                            </Typography>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>
                        {new Date(item.updatedAt).toLocaleString()}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Box>
    </div>
  );
};

export default DocumentSubPage;
