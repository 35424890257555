export interface orgData {
  orgName: string;
  orgUrl: string;
  descope_tenantId: string;
  orgService: OrgService[];
  orgLogo: {
    containerName: string;
    fileName: string;
    key: string;
    url: string;
  };
}

export interface Organization {
  dataChange: boolean;
  orgData: orgData;
}

export const orgInit: Organization = {
  dataChange: false,
  orgData: {
    orgName: "",
    orgUrl: "",
    descope_tenantId: "",
    orgLogo: {
      containerName: "",
      fileName: "",
      key: "",
      url: "",
    },
    orgService: [],
  },
};

export enum OrgService {
  MentalHealth = "Mental Health",
  AutismPractice = "Autism Practice",
}
