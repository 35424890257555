import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = ""

const currentTenantSlice = createSlice({
  name: "current-tenant",
  initialState: initialState,
  reducers: {
    setCurrentTenant: (state, action) => {
      state = action.payload
      return state
    },
    resetCurrentTenant: (state, action) => {
      state = ""
      return state
    },
  },
});

export const { setCurrentTenant, resetCurrentTenant } =
  currentTenantSlice.actions;

export default currentTenantSlice;
