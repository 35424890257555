import React, { Component } from "react";
import { Tabs } from "../../constants/tabs";
import { useTranslation } from "react-i18next"; // Import useTranslation hook
import "./HeaderTab.scss";
import Message from "../../assets/images/Message.png";

interface HeaderTabProps {
  onTabClick: (tabData: Tabs) => void;
  selectedTab: string;
  tabs: Tabs[];
}

const HeaderTab: React.FC<HeaderTabProps> = ({
  onTabClick,
  selectedTab,
  tabs,
}) => {
  const { t } = useTranslation(); // Initialize the useTranslation hook

  return (
    <>
      {tabs.map((tab: Tabs, index: number) => (
        <div
          onClick={() => onTabClick(tab)}
          key={`${tab.tabName + index}`}
          className={
            selectedTab === tab.tabName
              ? "menuItem--active menuItem"
              : "menuItem"
          }
        >
          <span className="menuItem__link">
            {/* Use t function to get translations */}
            {tab.tabName === "Messages" ? (
              <img
                src={Message}
                alt="Message icon"
                style={{ padding: "5px 0" }}
              />
            ) : (
              t(`main.header.${tab.tabName.toLowerCase()}`)
            )}
          </span>
        </div>
      ))}
    </>
  );
};

export default HeaderTab;
